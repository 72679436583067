/* .navbar{
  background: white;
}

.nav-link{
  color: blue;
} */


/* style='right: 0px; left: auto; text-align: center; text-indent: 0px; font-size: 18px' */

.meanmenu-reveal{
  right: 0px;
  left: auto;
  text-align: center;
  text-indent: 0px;
  font-size: 18px;
}

.about-us{
  padding: 3rem 5rem;
}

/* style='display: inline-block; padding:16px; border-radius: 8px; background-color: #25D366; color: #fff; text-decoration: none; font-family: sans-serif; font-size: 16px;' */

.whatsapp{
display: inline-block;
padding: 16px;
border-radius: 8px;
background: #25D366;
color: #fff;
text-decoration: none;
font-family: sans-serif; 
font-size: 16px;
}